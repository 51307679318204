/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import Image from "../components/image"
import Link from "gatsby-theme-flex/src/components/link"
import { useMediaQuery } from 'react-responsive'

const titleStyle = {
  color: `text`,
  "&:hover": {
    color: `linkHover`,
  },
  textAlign: "center",
  marginTop: 0,
  fontWeight: 900,
  textDecoration: "none",
};

const titleStyleDesktop = {
  ...titleStyle,
  fontSize: 20,
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 576 })
  return isMobile ? children : null
}

const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 577 })
  return isNotMobile ? children : null
}

const CardCase = React.forwardRef(
  (
    {
      style,
      type = `default`,
      heading,
      imageUrl,
      imageAlt,
      imageStyle,
      title,
      url,
      children,
      ...props
    },
    ref
  ) => (
    <div>
      <Link to={url} style={titleStyleDesktop}>
      <Mobile>
        <div
          ref={ref}
          sx={{
            variant: `card.${type}`,
            ...style,
            height: 220,
          }}
          {...props}
        >
          {imageUrl && (
            <Image src={imageUrl} alt={imageAlt || heading} style={imageStyle} />
          )}

          <div>
            <p style={titleStyle}>{title}</p>
            {children}
          </div>
        </div>
      </Mobile>
      <Default>
        <div
          ref={ref}
          sx={{
            variant: `card.${type}`,
            ...style,
            height: 260,
          }}
          {...props}
        >
          {imageUrl && (
            <Image src={imageUrl} alt={imageAlt || heading} style={imageStyle} />
          )}

          <div>
            <p style={titleStyle}>{title}</p>
            {children}
          </div>
        </div>
      </Default>
      </Link>
    </div>
  )
)

CardCase.propTypes = {
  style: PropTypes.object,
  type: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  imageStyle: PropTypes.object,
  url: PropTypes.string,
  children: PropTypes.node,
}

export default CardCase
