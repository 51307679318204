/** @jsx jsx */
import { jsx } from "theme-ui"
import confirmed from '../../assets/images/confirmed_blue.svg';

const ConfirmedSVG = () => {
  return (
    <figure>
      <img
        alt="confirmed"
        src={confirmed}
        style={{
          width: 150,
        }}
      />
    </figure>
  );
}

export default ConfirmedSVG;
