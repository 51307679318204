/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { jsx, Alert, Button, Styled } from "theme-ui";
import Link from "gatsby-theme-flex/src/components/link";

const hideOnPages = ["privacy-policy", "terms-of-service"];

const CookieWarning = React.forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const sectionStyle = {
    position: "fixed",
    zIndex: 9,
    bottom: 0,
    maxWidth: ["100%", "400px"],
  };

  const alertStyle = {
    backgroundColor: "#2d2d2d",
    color: "white",
    margin: ["0px", "10px"],
    borderRadius: ["0px", "8px"],
  };

  const buttonContainer = {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  };

  const buttonStyle = {
    color: "white",
    borderRadius: "8px",
    fontWeight: "bold",
    margin: "5px 20px",
    width: "130px",
    alignItems: "center",
    justifyContent: "center",
  };

  const acceptStyle = {
    ...buttonStyle,
    borderWidth: "0px",
    fontWeight: "bold",
    backgroundColor: "#0098f9",
  };

  const linkStyle = {
    color: "white",
    fontWeight: "bold",
  };

  const messageStyle = {
    padding: "0px 5px",
    color: "white",
  };

  const closeWindow = () => {
    window.localStorage.setItem("cookieWarning", 1);
    setIsOpen(false);
  };

  useEffect(() => {
    if (window !== null) {
      const isSelectedPage = hideOnPages.includes(
        window.location.pathname.replace(/^\/|\/$/g, "")
      );
      window["ga-disable-UA-165030656-1"] = isSelectedPage;
      if (
        window.localStorage.getItem("cookieWarning") !== "1" &&
        !isSelectedPage
      ) {
        setIsOpen(true);
      }
    }
  }, [isOpen]);

  const Buttons = () => {
    return (
      <div style={buttonContainer}>
        <Button onClick={() => closeWindow()} style={acceptStyle}>
          Fechar
        </Button>
      </div>
    );
  };

  const Message = () => {
    return (
      <div style={messageStyle}>
        <Styled.h4 sx={{ color: "white" }}>Este site utiliza cookies</Styled.h4>
        <Styled.h5 sx={{ fontWeight: "normal" }}>
          Nós utilizamos cookies para melhorar a navegação e a experiência do
          usuário. Ao continuar navegando, você concorda com a nossa{" "}
          <Link to="privacy-policy" style={linkStyle}>
            Política de Privacidade
          </Link>
          .
        </Styled.h5>
      </div>
    );
  };

  return (
    isOpen && (
      <section ref={ref} sx={sectionStyle}>
        <Alert sx={alertStyle}>
          <div>
            <Message />
            <Buttons />
          </div>
        </Alert>
      </section>
    )
  );
});

export default CookieWarning;
