import React from "react"
import { Helmet } from "react-helmet"

class ApplicationHelmet extends React.Component {
  render() {
    return (
      <div className="application">
        <Helmet>
          <title>Stoq Tecnologia</title>
          <meta name="description" content="A transformação digital do varejo" />
          <meta charSet="utf-8" />

          <meta itemprop="name" content="Stoq Tecnologia" />
          <meta itemprop="description" content="A transformação digital do varejo" />
          <meta itemprop="image" content="/icon.jpg" />

          <meta property="og:url" content="https://stoq.com.br" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Stoq Tecnologia" />
          <meta property="og:description" content="A transformação digital do varejo" />
          <meta property="og:image" content="/icon.jpg" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Stoq Tecnologia" />
          <meta name="twitter:description" content="A transformação digital do varejo" />
          <meta name="twitter:image" content="/icon.jpg" />
        </Helmet>
      </div>
    )
  }
}

export default ApplicationHelmet
