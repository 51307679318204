/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { useMediaQuery } from 'react-responsive'

const Elements = ({ children, minValue }) => {
  const isNotMobile = useMediaQuery({ minWidth: minValue })
  return isNotMobile ? children : null
}

const Desktop = React.forwardRef(({ style, children, minWidth=577, ...props }, ref) => (
  <Elements minValue={minWidth}>{children}</Elements>
))

Desktop.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
  minWidth: PropTypes.number,
}
 
export default Desktop
