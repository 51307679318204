import React from 'react'
import Modal from 'react-modal'
import Button from "gatsby-theme-flex/src/components/button"
import MediaQuery from 'react-responsive'

export default class ContactForm extends React.Component {
  render() {
    const labelStyle = {
      padding: 10,
    };

    const inputStyle = {
      marginTop: 10,
      borderRadius: 6,
      borderColor: "#CED7DB",
      fontFamily: "Red Hat Display, sans-serif",
      fontSize: 14,
    };

    const customStyles = {
      overlay: {
        position: "fixed",
        zIndex: 3,
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
      },
      content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        position: "absolute",
        width: "55%",
      }
    };

    const customStylesMobile = {
      overlay: {
        position: "fixed",
        zIndex: 3,
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
      },
      content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        position: "absolute",
        width: "90%",
      }
    };

    const buttonStyle = {
      border: 0,
      borderRadius: 8,
      backgroundColor: `buttonSecondaryBg`,
      "&:hover": {
        backgroundColor: `buttonSecondaryBgHover`,
      },
      fontSize: 16,
      fontWeight: "bold",
      color: "white",
    };

    const closeButtonStyle = {
      position: "absolute",
      top: 0,
      right: 0,
      border: "none",
      backgroundColor: "white",
      display: "block",
      marginLeft: "auto",
    };

    const linkStyle = {
      color: "#0098f9", textDecoration: "none"
    };

    return (
      <div>
        <MediaQuery minDeviceWidth={576}>
          {(matches) => matches
          ?
          <Form
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            customStyles={customStyles}
            buttonStyle={buttonStyle}
            closeButtonStyle={closeButtonStyle}
            linkStyle={linkStyle}
          />
          :
          <Form
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            customStyles={customStylesMobile}
            buttonStyle={buttonStyle}
            closeButtonStyle={closeButtonStyle}
            linkStyle={linkStyle}
          />}
        </MediaQuery>
      </div>
    );
  }
}

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {modalIsOpen: false};
  }

  componentDidMount() {
    Modal.setAppElement(`#___gatsby`);

    const loadScript = src => {
      let tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      let body = document.body;
      body.appendChild(tag);
    }

    const loadStyle = href => {
      let link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = href;
      let body = document.body;
      body.appendChild(link);
    }

    loadScript('//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js');
    loadScript('/static/form-messages.js');
    loadStyle('//cdn-images.mailchimp.com/embedcode/classic-10_7.css');
  }

  openModal = (e) => {
    this.setState({modalIsOpen: true})
  }

  closeModal = (e) => {
    this.setState({modalIsOpen: false})
  }

  render() {
    const termsOfService = <a href="terms-of-service" target="_blank" style={this.props.linkStyle}>Termos de Uso</a>;
    const privacyPolicy = <a href="privacy-policy" target="_blank" style={this.props.linkStyle}>Política de Privacidade</a>;

    return (
      <div>
        <Button onClick={this.openModal} style={this.props.buttonStyle}>Entre em contato</Button>
      <Modal
        isOpen={this.state.modalIsOpen}
        style={this.props.customStyles}
        onRequestClose={this.closeModal}
      >
      <div id="mc_embed_signup" style={{
        background:"#fff",
        clear:"left",
        font:"16px Red Hat Display Bold, sans-serif",
      }}>
        <form action="https://app.us19.list-manage.com/subscribe/post?u=b331ae04987ea93b8a8f66774&amp;id=c874272927" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
        <div style={{marginLeft: "5%", marginRight: "5%"}}>
          <button onClick={this.closeModal} style={this.props.closeButtonStyle}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                fill="currentColor"
              >
            <path
              stroke="#263238"
              fillRule="evenodd"
              d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
            /></svg></button>
          <p style={{
            font: "Red Hat Display Medium",
            fontSize: 20,
            color: "#263238",
            textAlign: "center",
          }}>Fale com a gente</p>
          <div className="mc-field-group">
              <label htmlFor="mce-EMAIL">
              <span style={this.props.labelStyle}>
                E-mail
              </span>
              <input size="60" type="email" name="EMAIL" className="required email" id="mce-EMAIL" style={this.props.inputStyle}/>
            </label>
          </div>
          <div className="mc-field-group">
            <label htmlFor="mce-NAME">
              <span style={this.props.labelStyle}>
                Nome
              </span>
              <input type="text" name="NAME" className="" id="mce-NAME" style={this.props.inputStyle}/>
            </label>
          </div>
          <div>
            <div className="mc-field-group size1of2">
              <label htmlFor="mce-PHONE">
                <span style={this.props.labelStyle}>
                  Telefone
                </span>
                <input type="text" name="PHONE" className="" id="mce-PHONE" style={this.props.inputStyle}/>
              </label>
            </div>
            <div className="mc-field-group size1of2">
              <label htmlFor="mce-COMPANY">
                <span style={this.props.labelStyle}>
                  Empresa
                </span>
                <input type="text" name="COMPANY" className="" id="mce-COMPANY" style={this.props.inputStyle}/>
              </label>
            </div>
          </div>
          <div className="mc-field-group">
            <label htmlFor="mce-MESSAGE">
              <span style={this.props.labelStyle}>
                Mensagem
              </span>
              <br/>
              <textarea cols="60" rows="5" name="MESSAGE" className="" id="mce-MESSAGE" style={{
                marginTop: 10,
                resize: "none",
                width: "100%",
                overflow: "auto",
                borderRadius: 6,
                borderColor: "#CED7DB",
                outline: "none",
                fontFamily: "Red Hat Display, sans-serif",
                fontSize: 14,
              }}/>
            </label>
          </div>

          <div id="mce-responses" className="clear">
            <div className="response" id="mce-error-response" style={{display:"none"}}></div>
            <div className="response" id="mce-success-response" style={{display:"none"}}></div>
          </div>
          <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
            <input type="text" name="b_a7477b9a2da031dded08fa020_5d2bfdb2bd" tabIndex="-1" />
          </div>
          <label style={{fontSize: 14}}>
            Ao clicar em "Enviar", você declara que leu e concorda com os {termsOfService} e
            a {privacyPolicy}.
          </label>
          <div className="clear" style={{marginTop: "20px"}}>
            <input type="submit" value="Enviar" name="subscribe" id="mc-embedded-subscribe" className="button" style={{
              display: "block",
              margin: "auto",
              width: 125,
              height: 42,
              backgroundColor: "#263238",
              borderRadius: 4,
            }}/>
          </div>
        </div>
        </form>
      </div>
      </Modal>
    </div>
    );
  }
};
