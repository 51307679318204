/** @jsx jsx */
import React from "react";
import { jsx, Styled, Container } from "theme-ui";
import Image from "./image";

const Banner = React.forwardRef((props, ref) => {
  const divStyle = {
    position: "fixed",
    display: "flex",
    zIndex: 10,
    backgroundColor: "#0d88ff",
    width: "100%",
    height: `calc(6vh + 10px)`,
    minHeight: "60px",
    alignItems: "center",
    cursor: "pointer",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: `space-between`,
  };

  const textContainerStyle = {
    flexDirection: "flex-column",
    margin: "10px 0px",
  };

  const h5Style = {
    color: "white",
    margin: "5px 0px 0px 0px",
  };

  const h6Style = {
    color: "white",
    margin: "0px 0px 5px 0px",
  };

  const imageStyle = {
    width: "100%",
    maxWidth: "120px",
    marginTop: "auto",
    marginBottom: "auto",
  };

  const handleOnClick = () => {
    if (window !== null) {
      window.open("https://ri.magazineluiza.com.br/");
    }
  };

  return (
    <div
      onClick={handleOnClick}
      onKeyDown={() => {}}
      style={divStyle}
      role="button"
      tabIndex={0}
    >
      <Container sx={containerStyle}>
        <div style={textContainerStyle}>
          <Styled.h4 style={h5Style}>A Stoq é Magalu!</Styled.h4>
          <Styled.h6 style={h6Style}>Confira aqui</Styled.h6>
        </div>
        <Image src="magaluLogo.jpg" style={imageStyle} />
      </Container>
    </div>
  );
});

export default Banner;
