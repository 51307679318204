/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { useMediaQuery } from 'react-responsive'

const Elements = ({ children, maxValue }) => {
  const isMobile = useMediaQuery({ maxWidth: maxValue })
  return isMobile ? children : null
}

const Mobile = React.forwardRef(({ style, children, maxWidth=576, ...props }, ref) => (
  <Elements maxValue={maxWidth}>{children}</Elements>
))

Mobile.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
  maxWidth: PropTypes.number,
}
 
export default Mobile
