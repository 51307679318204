/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { useMediaQuery } from 'react-responsive'
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { find } from "lodash"
import Img from "gatsby-image"

const Image = ({ src, srcMobile, alt, title, style }) => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { sourceInstanceName: { eq: "image" } }) {
        images: nodes {
          relativePath
          src: childImageSharp {
            fluid(cropFocus: CENTER, quality: 100) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  `)

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 599 })
    return isMobile ? children : null
  }

  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 600 })
    return isNotMobile ? children : null
  }

  const image = find(data.allFile.images, {
    relativePath: src,
  })

  const imageMobile = find(data.allFile.images, {
    relativePath: srcMobile,
  }) || image

  const imageStyle = {
    width: `100%`,
    overflow: `hidden`,
    m: `0`,
    img: {
      width: `100%`,
      height: `auto`,
    },
    ...style,
  }

  return (
    <figure
      sx={imageStyle}
    >
      <Mobile>
        {imageMobile ? (
          <Img fluid={imageMobile.src.fluid} alt={alt} title={title} />
        ) : (
          <Styled.img src={srcMobile} alt={alt} loading="lazy" />
        )}
      </Mobile>
      <Default>
        {image ? (
          <Img fluid={image.src.fluid} alt={alt} title={title} />
        ) : (
          <Styled.img src={src} alt={alt} loading="lazy" />
        )}
      </Default>
    </figure>
  )
}

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object,
}

export default Image
