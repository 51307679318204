/** @jsx jsx */
import { jsx, Flex, Container, Grid } from "theme-ui"
import { useMediaQuery } from 'react-responsive'
import PropTypes from "prop-types"
import Link from "gatsby-theme-flex/src/components/link"
import onovolab from "../../../assets/images/onovolab.svg"
import facebook from "../../../assets/images/facebook.svg"
import instagram from "../../../assets/images/instagram.svg"
import linkedin from "../../../assets/images/linkedin.svg"
import CookieWarning from "../../components/cookie-warning"

const Footer = ({ copyright }) => {
  const imageStyle = {
    width: `44px`, padding: `0.4em`
  };

  const addressStyle = {
    display: "inline-flex",
    paddingBottom: `1em`
  }

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 576 })
    return isMobile ? children : null
  }

  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 577 })
    return isNotMobile ? children : null
  }

  const address = [
    "Rua José Bonifácio, 565, sala 27",
    "Núcleo Residencial Silvio Vilari",
    "São Carlos - SP CEP 13560-610",]

  const linkStyle = {color:`link`, textDecoration: "none", display: "block"}

  return (
    <footer style={{background: "#F8F8F8"}}>
      <CookieWarning />
      <Container>
        <div style={{paddingTop: "1em"}}>
          <Mobile>
            <Link to="terms-of-service"
              style={{...linkStyle, textAlign: "center"}}>Termos de uso</Link>
            <Link to="privacy-policy"
              style={{...linkStyle, textAlign: "center"}}>Política de privacidade</Link>
          </Mobile>
          <Default>
            <Link to="terms-of-service" style={linkStyle}>Termos de uso</Link>
            <Link to="privacy-policy" style={linkStyle}>Política de privacidade</Link>
            <Link to="integrity-program" style={linkStyle}>Programa de integridade</Link>
          </Default>
        </div>
        <Flex
          sx={{
            flexDirection: [`column`, `row`],
            justifyContent: `start`,
            alignItems: [`center`],
            display: `flex`,
          }}
        >
          <Grid columns={[4, 4, 4]} gap={[1, 1, 1, 1, 1]} sx={{
            mt: [5, 5, 5]
          }} style={{textAlign: "center"}} >
            <Link to="https://onovolab.com/">
              <img alt="onovolab" src={onovolab} style={imageStyle}/>
            </Link>
            <Link to="https://www.facebook.com/StoqBR/">
              <img alt="facebook" src={facebook} style={imageStyle}/>
            </Link>
            <Link to="https://www.instagram.com/stoqbr/">
              <img alt="instagram" src={instagram} style={imageStyle}/>
            </Link>
            <Link to="https://www.linkedin.com/company/stoq-tecnologia-ltda">
              <img alt="linkedin" src={linkedin} style={imageStyle}/>
            </Link>
          </Grid>
          <Mobile>
            <p style={{
                ...addressStyle,
                textAlign: "center",
            }}>
              <small>{address[0]}<br/>
              {address[1]}<br/>
              {address[2]}</small></p>
          </Mobile>
          <Default>
            <p style={{
              ...addressStyle,
              marginLeft: "auto",
              textAlign: "right",
            }}>
              <small>{address[0]}<br/>
              {address[1]}<br/>
              {address[2]}</small></p>
          </Default>
        </Flex>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  copyright: PropTypes.string,
}

export default Footer
